<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form
              @submit.prevent="submitForm"
              class="pr-10"
              v-if="!registrationSuccess"
            >
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    Self Registration
                  </p>
                </div>
                <div class="flex flex-col gap-4">
                  <div class="relative">
                    <label
                      for="Email"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >Email</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="text"
                        id="Email"
                        v-model="formData.email"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        :class="
                          showError ? 'border-red-600' : 'border-gray-300'
                        "
                        placeholder="Enter Email"
                        :disabled="false"
                        @change="disableError"
                      />
                    </div>
                    <span
                      :class="{ hideError: !(showError && !isEmailValid) }"
                      class="text-red-600 text-xs error"
                      >Invalid Email</span
                    >
                    <span
                      v-if="showError && emailNotRegister && isEmailValid"
                      class="text-red-600 text-xs error"
                      >Your Email domain is not allowed for self registration,
                      kindly contact administer.</span
                    >
                  </div>
                  <div v-if="isOrganizationSelection">
                    <label
                      for="email"
                      class="block mb-2 text-sm font-medium text-gray-900 dark:text-white-text"
                      >Select an organization for a register with</label
                    >
                    <dropdown-base
                      :defaultHeightAndRound="false"
                      maxWidth="100%"
                      placeholder="Select Organization"
                      :searchable="true"
                      width="100px"
                      minWidth="100%"
                      :options="organizationOption"
                      :config="{ label: 'name', trackBy: 'org_id' }"
                      :style="`height: 50px; width: 300px`"
                      :disabled="organizationOptionDisabled"
                      :value="selectedOrganization"
                      @select="handleOrganizationSelection($event)"
                    />
                  </div>

                  <div v-if="isOrganizationSelection && showMultipleAuth">
                    <div class="flex gap-6">
                      <div class="flex gap-2">
                        <input
                          type="radio"
                          id="passwordSelection"
                          value="passwordSelection"
                          class="h-5 w-5 cursor-pointer"
                          v-model="authSelection"
                        />
                        <label for="passwordSelection">Password</label>
                      </div>
                      <div class="flex gap-2">
                        <input
                          type="radio"
                          id="ssoSelection"
                          value="ssoSelection"
                          class="h-5 w-5  cursor-pointer"
                          v-model="authSelection"
                        />
                        <label for="ssoSelection">SSO login</label>
                      </div>
                    </div>
                  </div>

                  <div
                    class="flex items-center justify-between py-4 gap-4 flex-wrap"
                    v-if="
                      authSelection === 'ssoSelection' && ssoList.length > 1
                    "
                  >
                    <div v-for="item in ssoList" :key="item.id">
                      <!-- @click="handleSsoLogin(item)" -->
                      <button
                        @click="handleSSoLogin(item)"
                        type="button"
                        class="sso-button h-8 w-32 justify-center text-brand bg-white hover:bg-primary-100 font-semibold rounded-md text-xs  text-center inline-flex items-center disabled:opacity-70 border-brand border-2"
                      >
                        <div>{{ item.display_name }}</div>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  class="flex flex-col gap-4"
                  :class="showError && emailNotRegister ? 'mt-4' : ''"
                  v-if="!isOrganizationSelection"
                >
                  <div class="flex flex-row justify-between gap-2 items-center">
                    <div
                      class="rounded-lg w-16 h-10 p-2 flex justify-center border-2 cursor-pointer transition-all hover:bg-primary-100 items-center"
                      @click="handleBackClick"
                    >
                      <font-awesome-icon icon="chevron-left" class="h-5" />
                    </div>
                    <button
                      class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-10 cursor-pointer login-button"
                      :class="{ btnDisable: !isFormFilled || disabled }"
                      type="submit"
                      :disabled="!isFormFilled || disabled"
                    >
                      <Loader
                        v-if="disabled"
                        class="h-6 w-6"
                        :size="6"
                      ></Loader>
                      {{ !disabled ? "Next" : "" }}
                    </button>
                  </div>
                  <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="NeotasLogo" class="poweredBy_logo" />
                  </div>
                </div>

                <div class="flex flex-col gap-4" v-if="showSubmitButton">
                  <button
                    class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-10 cursor-pointer login-button"
                    :class="{ btnDisable: disabledSubmit }"
                    type="submit"
                    :disabled="disabledSubmit"
                  >
                    <Loader v-if="disabled" class="h-6 w-6" :size="6"></Loader>
                    {{
                      !disabled && authSelection !== "ssoSelection"
                        ? "Submit"
                        : ssoList.length === 1
                        ? "SSO Login"
                        : ""
                    }}
                  </button>
                  <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="NeotasLogo" class="poweredBy_logo" />
                  </div>
                </div>
              </div>
            </form>

            <div
              class="flex flex-col gap-4 pr-0 pl-8 ml-4"
              v-if="registrationSuccess"
            >
              <div class="flex gap-2">
                <span><SuccessIcon /></span>
                <div class="text-2xl font-medium">Registration successful</div>
              </div>
              <div class="flex">
                Registration is successful and pending for an approval, once
                approved you will get an email notification.
              </div>
              <div class="flex">
                <button
                  @click="handleBackClick"
                  type="button"
                  class="sso-button h-10 px-4 justify-center text-brand bg-white hover:bg-primary-100 font-semibold rounded-md text-xs  text-center inline-flex items-center disabled:opacity-70 border-brand border-2"
                >
                  <div>Go back to login</div>
                </button>
              </div>
            </div>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
//   getLoginInfo
import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
import {
  getLoginInfo,
  verifyRegistrationEmail,
  selfRegisterViaPassword,
} from "../services";
//   import { verifyLoginEmail, getSsoRedirectUrl } from "../services";
import Loader from "@shared/loader";
import sections from "../store";
import DropdownBase from "@shared/components/dropdown-base";
import SuccessIcon from "@shared/assets/icon/svgs/circle_green_tick.svg";

export default {
  components: {
    Loader,
    DropdownBase,
    SuccessIcon,
    // RadioButton
  },
  data() {
    return {
      LoginImg,
      NeotasLogo,
      bgImg,
      // defaultLogo,
      disabled: false,
      requestId: null,
      formData: {
        email: "",
      },
      showError: false,
      // isEmailFilled: false,
      loginInfo: false,
      emailNotRegister: false,
      organizationOption: [],
      organizationOptionDisabled: false,
      selectedOrganization: null,
      showMultipleAuth: false,
      authSelection: null,
      registrationSuccess: false,
      ssoList: [],
      showSubmitButton: false,
      // ssoList: [{name: "Google", id: 1}, {name: "OKTA", id: 2}, {name: "Microsoft", id: 3}, {name: "Microsoft1", id: 4}]
    };
  },
  watch: {
    formData: {
      handler(newVal) {
        if (newVal.email === "") {
          this.showError = false;
          this.emailNotRegister = false;
        }
      },
      deep: true,
    },
  },
  async mounted() {
    if (this.$route?.query?.email) {
      this.formData.email = this.$route?.query?.email;
      // this.isEmailFilled = true;
    }
    await getLoginInfo();
    this.loginInfo = true;
    const selfRegistrationData = this.$store.getters.geSelfRegistrationData;
    // const selfRegistrationData = true;
    if (selfRegistrationData) {
      this.handleTheUiBasedOnData(selfRegistrationData);
    } else {
      this.$router.push("self-registration");
    }
  },
  methods: {
    isMultiAuth(organizationData) {
      return (
        Array.isArray(organizationData?.authentication_methods) &&
        organizationData?.authentication_methods?.length >= 1
      );
    },
    handleTheUiBasedOnData(data) {
      if (data?.data?.length === 1) {
        const selectedOrg = { ...data?.data[0], name: (data?.data[0]?.tenant_name && data?.data[0]?.client_name) ? data?.data[0]?.tenant_name + '-' + data?.data[0]?.client_name : data?.data[0]?.tenant_name ? data?.data[0]?.tenant_name : data?.data[0]?.client_name };
        this.handleOrganizationSelection(selectedOrg);
        this.organizationOptionDisabled = true;
      }
      this.setOrganizationDropdownOptions(data);
    },
    setOrganizationDropdownOptions(data) {
      this.organizationOption = data?.data.map((el) => ({
        ...el,
        name: (el.tenant_name && el?.client_name) ? el.tenant_name + '-' + el.client_name : el.tenant_name ? el.tenant_name : el.client_name,
      }));
    },
    handleOrganizationSelection(org) {
      // this.gotToInitialState();
      this.selectedOrganization = org;
      this.showSubmitButton = true;
      this.authSelection = null;
      this.manageSSoList(this.selectedOrganization);
      if (this.selectedOrganization?.authentication_methods.length === 1) {
        this.showMultipleAuth = false;
        if (
          this.selectedOrganization?.authentication_methods[0]?.auth_type ===
          "internal_cognito"
        ) {
          this.authSelection = "passwordSelection";
        } else {
          this.authSelection = "ssoSelection";
        }
      } else {
        this.showMultipleAuth = true;
      }
    },
    handleSSoLogin(authMethod) {
      try {
        let payload = {
          request_id: this.$route?.query?.request_id,
          email: this.$route?.query?.email,
          org_id: this.selectedOrganization?.org_id,
          org_type: this.selectedOrganization?.org_type,
        };
        payload.authentication_method_id = authMethod.authentication_method_id;
        this.$store.dispatch("ssoRedirectLogin", { payload, userType: "new" });
      } catch (err) {
        console.log("err", err);
      }
    },
    manageSSoList(data) {
      if (this.isMultiAuth(data)) {
        this.ssoList = data.authentication_methods.filter(
          (el) => el.auth_type === "external_idp"
        );
      }
    },
    async handlePasswordLogin(authMethod) {
      let payload = {
        request_id: this.$route?.query?.request_id,
        email: this.$route?.query?.email,
        org_id: this.selectedOrganization?.org_id,
        org_type: this.selectedOrganization?.org_type,
      };
      payload.authentication_method_id = authMethod.authentication_method_id;

      const res = await selfRegisterViaPassword(payload);
      if (
        res?.data?.success &&
        res?.data?.next_ui === "REGISTRATION_AWAITING_APPROVAL"
      ) {
        this.registrationSuccess = true;
        this.$toast.success(
          "Your registration is successful, pending for an approval, once approved you will get an email notification."
        );
      }
      if (
        res?.data?.success &&
        res?.data?.next_ui === "REGISTRATION_COMPLETE_LOGIN"
      ) {
        this.$toast.success(
          "Your registration is successful, you will get a temporary password on your register email"
        );
        this.$router.push("/login");
      }
    },
    handleMultipleAuth() {
      this.showMultipleAuth = true;
    },

    handleBackClick() {
      this.$router.push("/login");
    },
    async submitForm() {
      try {
        this.disabled = true;
        if (!this.isEmailValid) {
          this.showError = true;
          this.disabled = false;
          return;
        }

        if (this.isOrganizationSelection) {
          if (this.authSelection === "passwordSelection") {
            let authMethod = this.selectedOrganization.authentication_methods.find(
              (m) => m.auth_type === "internal_cognito"
            );
            this.handlePasswordLogin(authMethod);
            this.$store.dispatch("manageSelfRegistrationData", null);
            // this.$store.commit("SET_SELF_REGISTRATION_DATA", null);
            this.disabled = false;
            return;
          } else if (this.authSelection === "ssoSelection") {
            let authMethod = this.selectedOrganization.authentication_methods.find(
              (m) => m.auth_type === "external_idp"
            );
            this.handleSSoLogin(authMethod);
            this.$store.dispatch("manageSelfRegistrationData", null);
            // this.$store.commit("SET_SELF_REGISTRATION_DATA", null);
            this.disabled = false;
            return;
          }
          
        } else {
          let payload = {
            email: this.formData.email,
          };
          const res = await verifyRegistrationEmail(payload);
          if (
            res?.data?.success &&
            res?.data?.next_ui === "REQUEST_SELF_REGISTRATION_FORM"
          ) {
            this.$router.push({
              name: "SelfRegistrationUserDetail",
              query: {
                email: this.formData.email,
                request_id: res.data?.data?.request_id,
              },
            });
          } else if( res?.data?.success && res?.data?.next_ui === "LOGIN_FAILED") {
            this.showError = true;
            this.$toast.error(res?.data?.data?.message || "Something went wrong.")
            this.disabled = false;
            return
          }
          //   this.$router.push({
          //   name: "SelfRegistrationUserDetail",
          //   query: { email: this.formData.email },
          // });
        }
        // this.showError = true;
        // this.emailNotRegister = true;

        this.disabled = false;
      } catch (error) {
        console.log("error", error);
        this.disabled = false;
        this.$toast.error("Something went wrong!");
      }
    },

    disableError() {
      this.disabled = false;
    },
    isEmailFilled() {
      return this.formData.email;
    },

    isValidEmail(email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    },
  },
  computed: {
    isFormFilled() {
      return this.formData?.email;
    },
    isEmailValid() {
      return this.isValidEmail(this.formData.email);
    },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      // return sections.login_screen_info.logo_url
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
    isOrganizationSelection() {
      return !!this.$route?.query?.type || false;
    },
    disabledSubmit() {
      if(!this.isFormFilled || this.disabled) {
        return true;
      } else if(!this.authSelection) {
        return true;
      } else {
        return false;
      }
    }

    // showSubmitButton() {
    //   let flag = false;
    //   if(this.isOrganizationSelection && this.authSelection === 'passwordSelection') {
    //     console.log("h1")
    //     flag =  true;
    //   } else if(this.isOrganizationSelection && this.authSelection === 'ssoSelection') {
    //     flag =  this.ssoList.length ===  1 ? true : false
    //     console.log("h2")
    //   }
    //   return flag
    // },
  },
};

</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }

  // .loginImage-cnt {
  //     width: 50%;
  //     height: 100vh;
  //     // overflow: hidden;
  //     display: flex;
  // }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  // .rounded{
  //     border-radius: 10px;
  // }
  .form-cnt-div {
    padding: 3rem;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  .login-button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
    // background-color: #0d69d5;
    font-weight: 700;
  }
  .sso-button {
    border-width: 1px;
    font-size: 14px;
    // background-color: #0d69d5;
    font-weight: 500;
  }
  .forget-pass {
    color: rgb(13, 106, 213);
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .auth {
    overflow-y: scroll;
  }
  form {
    max-width: 36rem;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
