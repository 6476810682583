<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="">
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <!-- <Logo1/> -->
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <!-- <img v-else :src="Logo1" class="logo"> -->
                  <!-- <img v-else :src="defaultLogo" class="logo"> -->
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    Login to your account
                  </p>
                  <p class="block text-black text-sm">
                    Hi {{ activeUserName }}! We have shared a One Time Password
                    to this email <b>{{ activeUserEmail }}</b>
                  </p>
                </div>
                <div class="flex flex-col gap-4">
                  <div>
                    <p class="block text-black text-base">OTP</p>
                  </div>
                  <div class="otp-input relative">
                    <div class="otp-box-group" ref="otp-cnt">
                      <input
                        v-for="(item, idx) in formData.otp"
                        inputmode="numeric"
                        class="otp-box"
                        :class="showError ? 'invalid-field' : 'border-gray-300'"
                        maxlength="1"
                        v-model="formData.otp[idx]"
                        @input="handleOtpChange"
                        :key="idx"
                      />
                    </div>
                    <span
                      :class="{ hideError: !showError }"
                      class="text-red-600 text-sm error py-2"
                      >OTP Doesn't match</span
                    >
                  </div>
                 
                </div>
                <div>
                    <div class="flex items-center justify-between ">
                      <div class="text-brand cursor-pointer text-sm"  @click="changeEmailId">Change Email ID</div>
                      <div class="text-brand cursor-pointer text-sm"  @click="handleResend">Resend One-time Password?</div>
                    </div>
                    <!-- <p class="block text-black text-sm py-2">
                      Didn’t receive your OTP?
                      <span
                        class="text-primary cursor-pointer resend-otp"
                        @click="handleResend"
                        >Resend</span
                      >
                    </p> -->
                  </div>
                <div>
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer"
                    :class="{ btnDisable: !isFormFilled || disabled }"
                    type="submit"
                    :disabled="!isFormFilled || disabled"
                  >
                    <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
                    {{ !disabled ? "Confirm" : "" }}
                  </button>
                  <!-- <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="Logo1" class="poweredBy_logo" />
                  </div> -->
                </div>
              </div>
            </form>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
// import  LoginImg from "@/assets/svg/loginScreen.svg";
// import  Eye from "@/assets/svg/eye.svg";
// import VueCookies from "vue-cookies";
import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
import { validateGlobalAuthOtp, resendOtp, getLoginInfo, validateRegistrationOTP, sendOtp, resendRegOtp } from "./services";
import sections from "./store";
import { mapActions } from "vuex";
import Loader from "@shared/loader";

export default {
  components: {
    // Eye
    Loader,
  },
  data() {
    return {
      LoginImg,
      NeotasLogo,
      bgImg,
      // defaultLogo,
      disabled: false,
      requestId: null,
      formData: {
        otp: ["", "", "", "", "", ""],
      },
      currTime: null,
      showError: false,
      loginInfo: false,
      sendOtpPayload: null,
    };
  },
  async mounted() {
    this.otpField();
    await getLoginInfo();
    this.loginInfo = true;
    this.sendOtpPayload = this.$store.getters.getSendOtpPayload;
  },
  methods: {
    ...mapActions(["fetchPermissions"]),
    changeEmailId(){
      if(this.userComesFromSelfRegistration) {
        this.$router.push("/self-registration");
      } else {
        this.$router.push("/login");
      }
    },
    otpField() {
      const inputs = this.$refs["otp-cnt"];

      inputs.addEventListener("input", function(e) {
        const target = e.target;
        const val = target.value;
        if (isNaN(val) || val == " ") {
          target.value = "";
          return;
        }

        if (val != "") {
          const next = target.nextElementSibling;
          if (next) {
            next.focus();
          }
        }
      });

      inputs.addEventListener("keyup", function(e) {
        const target = e.target;
        const key = e.key.toLowerCase();

        if (key == "backspace" || key == "delete") {
          target.value = "";
          const prev = target.previousElementSibling;
          if (prev) {
            prev.focus();
          }
          return;
        }
      });

      inputs.addEventListener("paste", this.handlePaste);
    },
    handleOtpChange() {
      this.showError = false;
    },
    async submitForm() {
      try {
        this.disabled = true;
        const payload = {
            email: this.$route?.query?.email,
            request_id: this.$route?.query?.requestId,
            pin: this.formData.otp.join(""),
          };
        if(this.userComesFromSelfRegistration) {
          const res = await validateRegistrationOTP(payload);
          if(!res?.data?.status && res?.data?.status_code === 1004) {
            this.$toast.error(res?.data?.message || "something went wrong.")
            this.disabled = false;
            this.showError = true;
            return;
          } else {
            this.$store.dispatch("manageSelfRegistrationData", res.data);
            // this.$store.commit("SET_SELF_REGISTRATION_DATA", res.data);
            this.$router.push({
            name: "SelfRegistration",
            query: { email: this.$route?.query?.email, type: "organizationSelection",  request_id: res?.data?.request_id},
          }); 
          return;
          }
        }

        if(this.$route?.query?.type === "login-via-password") {

          const response = await validateGlobalAuthOtp(payload);
          if(!response?.data.status && response?.data?.status_code === 1004){
            this.showError = true;
            this.$toast.error(response?.data?.message || "Something went wrong!");
            this.disabled = false;
          } else {
            this.showError = false;
            this.$store.dispatch("manageGlobalAuthData", response.data);
            this.$router.push({
              name: "UnifiedPortalLogin",
              query: {requestId: this.$route.query?.requestId,  email: this.$route.query.email},
            });
            this.disabled = false;
          }
        return;
        }
        const response = await validateGlobalAuthOtp(payload);
        if(!response?.data.status && response?.data?.status_code === 1004){
            this.showError = true;
            this.$toast.error(response?.data?.message || "Something went wrong!");
            this.disabled = false;
        } else {
          this.showError = false;
          this.handleData(response.data);
          this.disabled = false;
        }
      
        
        // if (!response?.success) {
        //   if (response?.status_code == 1005 || response?.status_code == 1006) {
        //     this.$toast.error(response.message);
        //     this.moveToPath("login");
        //     return;
        //   }
        //   this.showError = true;
        //   this.$toast.error(response?.message || "Something went wrong!");
        // } else {
        //   VueCookies.set("token", response.data.access_token);
        //   VueCookies.set("refresh_token", response.data.refresh_token);
        //   if (response.data.access_token) {
        //     await this.fetchPermissions();
        //   }
        //   this.moveToPath("loginFlow");
        // }
      } catch (error) {
        this.$toast.error("Something went wrong!");
        this.disabled = false;
      }
    },
    handleData(data) {
      this.$store.dispatch("manageGlobalAuthData", data);
      this.moveToNextUi(data);
      // this.moveToPath("loginFlow");
    },
    moveToNextUi(data){
      switch(data.next_ui) {
        case "ORGANIZATION_SELECTION": {
          this.$router.push({
            name: "UnifiedPortalLogin",
            query: {requestId: this.$route.query?.requestId,  email: this.$route.query.email},
          });
          break;
        }
        case "AUTHENTICATION_METHOD_SELECTION": {
          this.$router.push({
            name: "UnifiedPortalLogin",
            query: {requestId: this.$route.query?.requestId,  email: this.$route.query.email},
          });
          break;
        }
        case "REQUEST_PASSWORD": {
          this.$router.push({
            name: "UnifiedPortalLogin",
            query: {requestId: this.$route.query?.requestId,  email: this.$route.query.email},
          });
          break;
        }
      }
    },
    moveToPath(type) {
      switch (type) {
        case "login": {
          this.$router.push("/");
          break;
        }
        case "loginFlow": {
          this.$router.push({
            name: "UnifiedPortalLogin",
            query: {requestId: this.$route.query?.requestId,  email: this.$route.query.email},
          });
          break;
        }
      }
    },
    async handleResend() {
      try {
        if (this.currTime) {
          const timeDiff = new Date().getTime() - this.currTime;
          if (timeDiff < 30000) {
            this.$toast.error(
              "Please wait for a total of 30 secs before resending the OTP!"
            );
            return;
          }
        }
        this.currTime = new Date().getTime();
        this.formData.otp = ["", "", "", "", "", ""];
        this.showError = false;
        const requestId = this.$route.query.requestId;
        const email = this.$route.query.email;
        let data;
        if(this.userComesFromSelfRegistration){
          let payload = this.$store.getters.getSelfRegUserDetail;
          data = await resendRegOtp(payload);
        } else if(this.$route?.query?.type === "login-via-password") {
          let payload = this.$store.getters.getSendOtpPayload;
          payload.request_id =  this.$route.query?.requestId;
          data = await sendOtp(payload);
        } else {
          data = await resendOtp({requestId, email});
        }
        console.log("data", data);
        if (data.success == true) {
          this.$toast.success("OTP sent successfully");
        } else {
          this.$toast.error(data?.message || "Something went wrong.");
        }
        // else {
        //   if (data?.status_code == 1005 || data?.status_code == 1006) {
        //     this.$toast.error(data?.message || "");
        //     this.moveToPath();
        //     return;
        //   }
        //   this.$toast.error(data.message);
        // }
      } catch (error) {
        this.$toast.error("Something went wrong!");
      }
    },
    async handlePaste(event) {
      let login_otp = event.clipboardData
        .getData("text/plain")
        .replace(/\s/g, "");
      this.formData.otp = login_otp.split("");
      this.formData.otp.length = 6;
      const inputs = document.querySelectorAll(".otp-box");
      const otp = this.formData.otp;
      for (let idx in otp) {
        inputs[idx].value = otp[idx];
        inputs[idx].focus();
      }
    },
  },
  computed: {
    isFormFilled() {
      return this.formData?.otp.join("").length == 6;
    },
    fillRequestId() {
      return this.$route.query;
    },
    activeUserEmail() {
      return sections.user_email;
    },
    activeUserName() {
      return sections.user_name;
    },
    isOtpEmpty() {
      return this.formData.otp.every((e) => e == "");
    },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
    userComesFromSelfRegistration() {
      return !!this.$route.query.from || false
    },
  },
  beforeRouteEnter (to, from, next) {
    if(from?.name === null) {
      next(vm => {
        vm.$router.push("login");
      })
    }else {
      next();
    }
  }
};
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }
  div,
  input {
    /* border: 1px solid red; */
  }

  .loginImage-cnt {
    width: 50%;
    height: 100vh;
    // overflow: hidden;
    display: flex;
  }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .otp-input {
    position: relative;
    width: fit-content;
    /* overflow: hidden */
  }

  .otp-box {
    border-radius: 10px;
    border: 1px solid rgba(170, 175, 221, 0.42);
    background: rgba(245, 245, 245, 0.4);
    width: 50px;
    height: 50px;
    padding: 12px 10px 12px 18px;
  }

  .otp-box:not(:last-child) {
    margin-right: 10px;
  }

  .otp-box:focus {
    border: 1px solid #3b82f6;
  }

  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  form {
    max-width: 36rem;
  }
  .rounded {
    border-radius: 10px;
  }
  .invalid-field {
    border: 1px solid red !important;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
  }
  .resend-otp {
    color: rgb(13, 106, 213);
  }
  .auth {
    overflow-y: scroll;
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
