var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"editPage"}},[_c('email-frequency',{on:{"refreshBookmark":_vm.getBookmarks}}),_c('div',{staticClass:"flex justify-between space-x-4"},[(_vm.showCaseCreation)?_c('PackageList',{staticClass:"mb-2"}):_vm._e(),_c('div',{staticClass:"flex justify-end space-x-4 ml-auto"},[_c('dropdown',{staticClass:"w-full",staticStyle:{"z-index":"501"},attrs:{"placeholder":_vm.$t('pages.bi_dashboard.saved_filters'),"options":_vm.bookmarksList,"config":{ label: 'name', trackBy: 'view_id' },"width":"392px","minWidth":"182px","colors":{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          }},on:{"handleIconClick":_vm.handleClick}}),_c('dropdown',{staticClass:"filter-dropdown h-10",staticStyle:{"z-index":"501"},attrs:{"reposition":"","searchable":"","placeholder":_vm.$t('pages.tenant_client.dashboards'),"options":_vm.dashboardOptions,"colors":{
            text: '#282828',
            bg: 'white',
            svg: '#282828',
            border: '#e8e8e8',
          },"limit":1,"width":"192px","minWidth":"102px","config":{ label: 'labelName', trackBy: 'dashboard_id' }},on:{"input":_vm.selectDashboard},model:{value:(_vm.selectedDashboard),callback:function ($$v) {_vm.selectedDashboard=$$v},expression:"selectedDashboard"}})],1)],1),_c('div',{staticClass:"mt-3",attrs:{"id":"dashboard_container"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }