import axios from "@/axios";

export default {
    async getDashboardBookmarks(dashboardId) {
        const url = `/dashboard/bookmarks/${dashboardId}`;
        const {data} = await axios.get(url);
        return data;
    },

    async updateDashboardBookmarks(viewId, payload) {
        const url = `/dashboard/bookmarks/${viewId}`;
        const {data} = await axios.put(url, payload);
        return data;
    },

    async saveDashboardViews(payload) {
        const url = "/dashboard/bookmarks";
        const {data} = await axios.post(url, payload);
        return data;
    },

    async addDashboardSchedule(payload) {
        const url = "/dashboard/add-scheduler";
        const {data} = await axios.post(url, payload);
        return data;
    },

    async getDashboardSchedule(viewId) {
        const url = `/dashboard/get-scheduler/${viewId}`;
        const {data} = await axios.get(url);
        return data;
    },

    async deleteBookmark(payload) {
        let url = `/dashboard/bookmarks/${payload.bookmarkId}`;
        if (payload?.scheduleId) {
            url = `/dashboard/bookmarks/${payload.bookmarkId}?${payload.scheduleId}`;
        }
        return await axios.delete(url);
    }
};