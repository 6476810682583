<template>
    <div>
    <div class="custom-container">
        <section class="brand">
            <div id="bg">
                <img :src="bgImg" class="loginImg"/>
            </div>
        </section>
        <section class="auth">
            <template >
            <div class="w-full form-cnt min-h-full">
                <form @submit.prevent="submitForm" class="pr-10">
                    <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                        <div v-if="loginInfo" :style="{paddingBottom:'6px'}">
                            <!-- <Logo1/> -->
                            <img v-if="loginScreenInfo.logo_url" :src="loginScreenInfo.logo_url" class="custom_logo">
                            <!-- <img v-else :src="Logo1" class="logo"> -->
                            <!-- <img v-else :src="defaultLogo" class="logo"> -->
                            <div class="tagline_cnt">
                                <span class="tagline">{{ loginScreenInfo.tagline}}</span>
                            </div>
                        </div>
                        <div>
                            <p class="block mb-2 text-black text-2xl font-semibold">{{ currentStep===1 ? $t("login.verify_email") : $t("login.enter_temp_password") }}</p>
                            <p v-if="currentStep===2" class="block mb-2 text-black text-sm">
                                {{ $t("login.temp_password_shared", { name: activeUserName, email: activeUserEmail }) }}
                            </p>
                        </div>
                        <div>
                            <div v-if="currentStep===1" class="relative">
                                <label for="Email" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">
                                    {{ $t("fields.email") }}
                                </label>
                                <div class="flex items-center relative">
                                    <input type="text" id="Email" disabled v-model="formData.email" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :class=" ( showError && !isEmailValid )  ? 'border-red-600' : 'border-gray-300'" :placeholder="$t('fields.placeholders.email')" >
                                </div>
                                <span :class="{'hideError':!(showError && !isEmailValid)}" class="text-red-600 text-xs error">{{ $t("fields.errors.email") }}</span>
                            </div>
                            <div class="my-8" v-else>
                            <label for="Temparary_password" class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white">
                                {{ $t("fields.temp_password") }}
                            </label>
                            <div class="flex items-center relative">
                                <input type="password" ref="passRef" id="Temparary_password" v-model="formData.password" autocomplete="new-password" class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12" :placeholder="$t('fields.placeholders.temp_password')" @blur="handleInputBlur">
                                <EyeClosed class="absolute right-4 cursor-pointer " @click="togglePass" v-if="!isPassVisible"/>
                                <Eye class="absolute right-4 cursor-pointer " @click="togglePass" v-else/>
                            </div>
                            <!-- <span v-if="showError && !isPassValid && formData.password" class="text-red-600 text-xs">Should be a minimum of 11 characters</span> -->
                            </div>
                        </div>
                        <div>
                            <button class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer" :class="{'btnDisable':!isFormFilled || disabled}" type="submit" :disabled="!isFormFilled || disabled">
                                <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
                                {{ !disabled ? $t("actions.continue") : '' }}
                            </button>
                            <div class="flex justify-center items-center powered_by_cnt" v-if="loginScreenInfo.powered_by && loginInfo">
                                <span class="poweredBy">{{ $t("general.powered_by") }}</span>
                                <img :src="NeotasLogo" class="poweredBy_logo"/>
                                <!-- <img class="poweredBy_logo" :src="poweredByImgUrl"/> -->
                            </div>
                        </div>
                    </div>
                </form>
            </div>
            </template>
        </section>
    </div>
</div>
</template>
<script>
    // import  LoginImg from "@/assets/svg/loginScreen.svg";
    import  Eye from "@/assets/svg/eye.svg";
    import  EyeClosed from "@/assets/svg/closeeye.svg";
    import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
    import {requestResetPassword, verifyGlobalAuthTempPass, getLoginInfo} from "./services"
    import sections from "./store";
    import Loader from "@shared/loader";

    export default {
    components: {
        Eye,
        EyeClosed,
        Loader,
    },
    data(){
        return {
            LoginImg,
            NeotasLogo,
            bgImg,
            // defaultLogo,
            formData: {
                email: '',
                password: ''
            },
            requestId:null,
            currentStep: 1,
            showError: false,
            isPassVisible: false,
            disabled: false,
            loginInfo: false,
        }
    },
    async mounted(){
        await getLoginInfo();
        this.loginInfo = true;
        if (this.$route?.params?.email) {
            this.formData.email = this.$route?.params?.email;
        }
    },
    methods: {
        async submitForm() {
            try {
                this.disabled = true;
                this.showError = true;
                let payload = {
                        request_id: this.$route?.query?.request_id,
                        org_type:  this.$route?.query?.org_type,
                        org_id: this.$route?.query?.org_id,
                    }
                if(this.currentStep == 1 && !this.isEmailValid) return this.disabled = false
                if (this.currentStep== 1) {
                    payload.email = this.formData.email;
                    const data = await requestResetPassword(payload);
                    this.disabled = false;
                    if (data.status==false) {
                        this.$toast.error(data.message || this.$t("general.default_error"))
                        return
                    }
                    this.$toast.success(this.$t("login.temp_password_send_success"))
                    this.requestId = data.data.request_id
                    this.nextStep()
                    return

                }
                if (this.currentStep==2 && this.isPassValid){
                    // const password = this.formData.password
                    const requestPayload ={
                        request_id: this.$route?.query?.request_id,
                        temp_password: this.formData.password,
                    }
                    const data = await verifyGlobalAuthTempPass(requestPayload);
                    if (data.status_code == 1005 || data.status_code == 1006) {
                        this.$toast.error(data.message || this.$t("general.default_error"))
                        this.$router.push("/")
                        return
                    } else if(data.status_code == 1013) {
                        this.$toast.error(data.message || this.$t("general.default_error"))
                    } else if(data.status_code === 1000) {
                        this.$toast.success(this.$t("login.temp_password_verify_success"))
                        this.moveToPath();
                        return;
                    }
                    this.disabled = false;
                }
                this.disabled = false
            } catch (error) {
                this.disabled = false;
                this.$toast.error(error.message || this.$t("general.default_error"))
            } 
        },
        togglePass(){
                this.$refs.passRef.type = this.$refs.passRef.type==='text' ? 'password' : 'text';
                this.isPassVisible = !this.isPassVisible;
        },
        handleInputBlur(){
            // this.$refs.passRef.type = 'password';
        },
        nextStep(){
            this.showError = false;
            this.currentStep++;
        },
        moveToPath(){
            // this.$router.push("update-password")
            // const params = this.$route.query ;
            // params.request_id = this.requestId ;
            // params.set_type = "Forgot"
            this.$router.push({ 
                name: 'update-password', 
                // query: params,
                query: { requestId: this.$route?.query?.request_id, orgId: this.$route?.params?.org_id, orgType: this.$route?.params?.org_type, email: this.formData.email.trim(), set_type: "Forgot"},
                params: { requestId: this.$route?.query?.request_id, orgId: this.$route?.params?.org_id, orgType: this.$route?.params?.org_type, email: this.formData.email.trim(), set_type: "Forgot"},    
            })
        },
        isValidEmail(email){
            const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
            return emailRegex.test(email);
        }
  },
  computed: {
    isFormFilled(){
        return this.currentStep===1 ? this.formData?.email : this.formData?.password
    },
    isEmailValid(){
        return this.isValidEmail(this.formData.email)
    },
    activeUserEmail(){
        return sections.user_email;
    },
    activeUserName(){
        return sections.user_name;
    },
    isPassValid(){
        return this.formData?.password.length ; 
    },
    loginScreenInfo(){
        return sections.login_screen_info
    },
    poweredByImgUrl(){
        return `data:image/png;base64,${this.loginScreenInfo.logo}`
    },
  }
}
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;   
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
}
    .loginImage-cnt {
        width: 50%; 
        height: 100vh; 
        // overflow: hidden; 
        display: flex;
    }
    .form-cnt{
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 6%;
        background-color: white;
    }
    form{
        max-width: 36rem;
    }
    .logo{
        width: 200px;
        height: 72px;
    }
    .btnDisable{
        background-color: #DDD;
        color: #858585;
    }
    .rounded{
        border-radius: 10px;
    }
    input{
        font-size: 16px;
        border-radius: 10px;
        // border: 1px solid  #d8dbe0;
    }
    input:focus{
        outline: 1px solid rgb(59,130,246);
    }
    input::placeholder{
        // color: #49454F;
    }
    button{
        border-radius: 10px;
        border-width: 0px;
        font-size: 16px;
        font-weight: 700;
    }
    .auth{
        overflow-y : scroll;
    }
    *{
        font-family: "Poppins", sans-serif !important;
    }
    .hideError{
        visibility: hidden;
    }
    .error{
        position: absolute;
    }
    .loginImg{
        width: 100%;
        height: 100%;
    }
    .tagline{
        font-size: 16px;
        font-weight: 600;
    }
    .poweredBy{
        font-size: 12px;
    }
    .poweredBy_logo{
        max-height: 28px;
        min-height: 20px;
        margin-left: 10px;
    }
    .powered_by_cnt{
        margin-top: 15px;
    }
    .tagline_cnt{
        margin-top: 19px;
    }
    .custom_logo{
        max-height: 130px;
        min-height: 72px;
    }
  }
</style>