<!-- Localized -->
<template>
  <DefaultDashboard
    :dashboard-id="dashboardId"
  />
</template>

<script>
import DefaultDashboard from "@/components/dashboard-components/dashboard-view/DefaultDashboard"

export default {
  name: "case-dashboard",
  components: {
    DefaultDashboard,
  },
  props: {
    // TODO check if may be removed
    isDefaultDashboard: {
      type: Boolean,
      default: false,
    },
  },
  title() {
    return `Case Dashboard`;
  },
  data() {
    return {};
  },
  computed: {
    dashboardId() {
      return this.$route.params.id;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
</style>
