<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="pr-10">
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <!-- <Logo1/> -->
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <!-- <img v-else :src="Logo1" class="logo"> -->
                  <!-- <img v-else :src="defaultLogo" class="logo"> -->
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    Login to your account
                  </p>
                  <p class="block mb-2 text-black text-sm">
                    Hi {{ activeUserName }}! Please enter a New Password
                  </p>
                </div>
                <div>
                  <div class="my-8 relative">
                    <label
                      for="Temparary_password"
                      class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white"
                      >New Password</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="password"
                        ref="passRef"
                        id="password"
                        v-model="formData.password"
                        autocomplete="new-password"
                        class="bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12"
                        :class="
                          (passwordCheck && !isPassValidReqX) ||
                          (!isPassValidReqX && isPassFilled)
                            ? 'border-red-600'
                            : 'border-gray-300'
                        "
                        placeholder="Enter Password"
                        @blur="handleInputBlur"
                        @input="inputCheck"
                      />
                      <EyeClosed
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass('passRef')"
                        v-if="!isPassVisible"
                      />
                      <Eye
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass('passRef')"
                        v-else
                      />
                    </div>
                    <span
                      v-if="passwordCheck && !isPassValidReqX"
                      class="text-red-600 text-xs"
                      >Password must contain atleast 12 characters, a lower case
                      letter, an uppercase letter, a number and a special
                      character.</span
                    >
                    <!-- <span :class="{'hideError': !passwordCheck || (isPassValidReqX && isPassFilled) }" class="text-red-600 text-xs error">Password must contain atleast 12 characters, a lower case letter, an uppercase letter, a number and a special character.</span> -->
                  </div>
                  <div class="my-8 relative">
                    <label
                      for="Temparary_password"
                      class="block mb-2 text-sm font-noraml text-gray-900 dark:text-white"
                      >Confirm Password</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="password"
                        ref="confPassRef"
                        id="confPassword"
                        v-model="formData.confPassword"
                        autocomplete="new-password"
                        class="bg-gray-50 border text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12"
                        :class="
                          passConfCheck && !isConfPassValid
                            ? 'border-red-600'
                            : 'border-gray-300'
                        "
                        placeholder="Re Enter Password"
                        @blur="handleInputBlur"
                        @change="handleChange"
                        @input="confPassInputCheck"
                      />
                      <EyeClosed
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass('confPassRef')"
                        v-if="!isconfPassVisible"
                      />
                      <Eye
                        class="absolute right-4 cursor-pointer "
                        @click="togglePass('confPassRef')"
                        v-else
                      />
                    </div>
                    <span
                      :class="{
                        hideError:
                          !passConfCheck ||
                          (isConfPassValid && isConfPassFilled),
                      }"
                      class="text-red-600 text-xs error"
                      >Password doesn't match</span
                    >
                  </div>
                </div>
                <div>
                  <button
                    class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer"
                    :class="{ btnDisable: !isFormFilled || disabled }"
                    type="submit"
                    :disabled="!isFormFilled || disabled"
                  >
                    <Loader v-if="disabled" class="h-8 w-8" :size="8"></Loader>
                    {{ !disabled ? "Continue" : "" }}
                  </button>
                  <div
                    class="flex justify-center items-center powered_by_cnt"
                    v-if="loginScreenInfo.powered_by && loginInfo"
                  >
                    <span class="poweredBy">Powered by</span>
                    <img :src="NeotasLogo" class="poweredBy_logo" />
                    <!-- <img class="poweredBy_logo" :src="poweredByImgUrl"/> -->
                  </div>
                </div>
              </div>
            </form>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
// import  LoginImg from "@/assets/svg/loginScreen.svg";
import Eye from "@/assets/svg/eye.svg";
import EyeClosed from "@/assets/svg/closeeye.svg";
import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
import Loader from "@shared/loader";
import { submitResetPassword, setGlobalAuthNewPassword, getLoginInfo } from "./services";
import sections from "./store";

export default {
  components: {
    Eye,
    EyeClosed,
    Loader,
  },
  data() {
    return {
      LoginImg,
      NeotasLogo,
      bgImg,
      // defaultLogo,
      isPassVisible: false,
      isconfPassVisible: false,
      formData: {
        password: "",
        confPassword: "",
      },
      personalData: {
        username: "User",
      },
      showError: false,
      disabled: false,
      passwordCheck: false,
      passConfCheck: false,
      loginInfo: false,
    };
  },
  async mounted() {
    if (this.getUserName) {
      this.personalData.username = this.getUserName;
    }
    await getLoginInfo();
    this.loginInfo = true;
  },
  methods: {
    inputCheck() {
      this.passwordCheck = true;
    },
    confPassInputCheck() {
      this.passConfCheck = true;
    },
    submitForm() {
      this.handleValidation();
    },
    togglePass(ref) {
      this.$refs[ref].type =
        this.$refs[ref].type === "text" ? "password" : "text";
      if (ref === "passRef") {
        this.isPassVisible = !this.isPassVisible;
      } else if (ref === "confPassRef") {
        this.isconfPassVisible = !this.isconfPassVisible;
      }
    },
    handleInputBlur() {
      // this.$refs.passRef.type = 'password';
    },
    moveToPath() {
      this.$router.push("/login");
    },
    async handleValidation() {
      try {
        this.disabled = true;
        if (
          this.isPassValidReqX &&
          this.formData?.password === this.formData?.confPassword
        ) {
        //   const requestId = this.$route.query.request_id;
          const setType = this.$route?.query?.set_type
            
          // const payload = {
          //     "request_id": requestId,
          //     "password": this.formData.password,
          //     "set_type": setType
          // }
          const payload = {
            request_id: this.$route.query.requestId,
            org_id: this.$route.query.orgId,
            org_type: this.$route.query.orgType,
            email: this.$route.query.email,
            password: this.formData.password,
            set_type: setType,
          };
          if (!payload.request_id) {
            this.$toast.error("Something went wrong!");
            this.moveToPath();
            return;
          }
          const data = setType === "New" ? await setGlobalAuthNewPassword(payload) :  await submitResetPassword(payload);
          this.disabled = false;
          if (data.status == false) {
            if (data.status_code == 1005 || data.status_code == 1006) {
              this.$toast.error(data.message);
              this.$router.push("/");
              return;
            }
            this.$toast.error(data.message || "Something went wrong!");
            return;
          }
          this.$toast.success("Password updated successfully!");
          this.moveToPath();
        } else this.showError = true;
        this.disabled = false;
      } catch (error) {
        this.$toast.error("Something went wrong!");
      }
    },
    handleChange() {
      this.showError = false;
    },
  },
  computed: {
    isFormFilled() {
      return this.formData?.password && this.formData?.confPassword;
    },
    isPassFilled() {
      return this.formData?.password.length;
    },
    isConfPassFilled() {
      return this.formData?.confPassword.length;
    },
    isPassValidReqX() {
      const regexPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{12,}$/;
      if (this.formData.password.length == 0) {
        this.passwordCheck = false; //eslint-disable-line
      }
      // Perform validation on the inputValue using the regex pattern
      return regexPattern.test(this?.formData?.password);
    },
    getUserName() {
      return sections.user_name;
    },
    isConfPassValid() {
      if (this.formData.confPassword.length == 0) {
        this.passConfCheck = false; //eslint-disable-line
      }
      return this.formData?.password === this.formData?.confPassword;
    },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
    activeUserName() {
      return sections?.user_name;
    },
  },
};
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }

  .loginImage-cnt {
    width: 50%;
    height: 100vh;
    // overflow: hidden;
    display: flex;
  }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  form {
    max-width: 36rem;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  .rounded {
    border-radius: 10px;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
    font-weight: 700;
  }
  .auth {
    overflow-y: scroll;
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    // position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
