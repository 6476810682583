<template>
  <div>
    <div class="custom-container">
      <section class="brand">
        <div id="bg">
          <img :src="bgImg" class="loginImg" />
        </div>
      </section>
      <section class="auth">
        <template>
          <div class="w-full form-cnt min-h-full">
            <form @submit.prevent="submitForm" class="pr-10">
              <div class="flex flex-col gap-8 pr-0 pl-8 ml-4">
                <div v-if="loginInfo" :style="{ paddingBottom: '6px' }">
                  <img
                    v-if="loginScreenInfo.logo_url"
                    :src="loginScreenInfo.logo_url"
                    class="custom_logo"
                  />
                  <div class="tagline_cnt">
                    <span class="tagline">{{ loginScreenInfo.tagline }}</span>
                  </div>
                </div>
                <div>
                  <p class="block mb-2 text-black text-2xl font-semibold">
                    Provide your name detail bellow
                  </p>
                </div>
                <div class="flex flex-col gap-4">
                  <div class="relative">
                    <label
                      for="Email"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >Email</label
                    >
                    <div class="flex items-center relative">
                      <input
                        type="email"
                        id="Email"
                        v-model="formData.email"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        :class="
                          showError && !isEmailValid
                            ? 'border-red-600'
                            : 'border-gray-300'
                        "
                        placeholder="Enter your email"
                        :disabled="isEmailFilled"
                        @change="disableError"
                      />
                    </div>
                    <span
                      :class="{ hideError: !(showError && !isEmailValid) }"
                      class="text-red-600 text-xs error"
                      >Invalid Email</span
                    >
                  </div>
                  <div class="relative">
                    <label
                      for="First Name"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >First Name</label
                    >
                    <div class="flex items-center relative">
                      <!-- :disabled="isFirstNameValid" && !isFirstNameValid-->
                      <input
                        type="text"
                        id="firstName"
                        v-model="formData.firstName"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        :class="
                          showError ? 'border-red-600' : 'border-gray-300'
                        "
                        placeholder="Enter your first name"
                        @change="disableError"
                      />
                    </div>
                    <!-- <span
                      :class="{ hideError: !(showError && !isFistNameValid) }"
                      class="text-red-600 text-xs error"
                      >Invalid First Name</span
                    > -->
                  </div>
                  <div class="relative">
                    <label
                      for="Last Name"
                      class="block mb-2 text-sm font-normal text-gray-900 dark:text-white"
                      >Last Name</label
                    >
                    <div class="flex items-center relative">
                      <!-- :disabled="isFirstNameValid" && !isFirstNameValid -->
                      <input
                        type="text"
                        id="LastName"
                        v-model="formData.lastName"
                        class="bg-gray-50 border border-gray-300 gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-10"
                        :class="
                          showError ? 'border-red-600' : 'border-gray-300'
                        "
                        placeholder="Enter your last name"
                        @change="disableError"
                      />
                    </div>
                    <!-- <span
                      :class="{ hideError: !(showError && !isLastNameValid) }"
                      class="text-red-600 text-xs error"
                      >Invalid Last Name</span
                    > -->
                  </div>
                </div>
                <div class="flex flex-col gap-4">
                  <div class="flex flex-row justify-between gap-2 items-center">
                    <div
                      class="rounded-lg w-16 h-10 p-2 flex justify-center border-2 cursor-pointer transition-all hover:bg-primary-100 items-center"
                      @click="handleBackClick"
                    >
                      <font-awesome-icon icon="chevron-left" class="h-5" />
                    </div>
                    <button
                      class="form-btn bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-10 cursor-pointer login-button"
                      :class="{ btnDisable: !isFormFilled || disabled }"
                      type="submit"
                      :disabled="!isFormFilled || disabled"
                    >
                      <Loader
                        v-if="disabled"
                        class="h-8 w-8"
                        :size="8"
                      ></Loader>
                      {{ !disabled ? "Next" : "" }}
                    </button>
                  </div>

                  <!-- <div class="flex justify-start items-center">
                      Don't have an account?
                      <span class="px-1 text-brand cursor-pointer">Register</span>
                    </div> -->
                  <!-- <div
                      class="flex justify-center items-center powered_by_cnt"
                      v-if="loginScreenInfo.powered_by && loginInfo"
                    >
                      <span class="poweredBy">Powered by</span>
                      <img :src="NeotasLogo" class="poweredBy_logo" />
                    </div> -->
                </div>
              </div>
            </form>
          </div>
        </template>
      </section>
    </div>
  </div>
</template>
<script>
//   getLoginInfo
import { LoginImg, NeotasLogo, bgImg } from "@/plugins/common.js";
import {
  getLoginInfo,
  requestUserRegistration,
} from "../services";
import Loader from "@shared/loader";
import sections from "../store";

export default {
  components: {
    Loader,
  },
  data() {
    return {
      LoginImg,
      NeotasLogo,
      bgImg,
      // defaultLogo,
      disabled: false,
      requestId: null,
      formData: {
        email: "",
        firstName: "",
        lastName: "",
      },
      showError: false,
      isEmailFilled: false,
      isFirstNameFilled: false,
      isLastNameFilled: false,
      loginInfo: false,
    };
  },
  async mounted() {
    if (!this.$route?.query?.request_id) {
      this.$router.push("/login");
    }
    if (this.$route?.query?.email) {
      this.formData.email = this.$route?.query?.email;
      this.isEmailFilled = true;
    }
    await getLoginInfo();
    this.loginInfo = true;
  },
  methods: {
    handleBackClick() {
      this.$router.push({
        name: "SelfRegistration",
        query: { email: this.formData.email },
      });
    },
    async submitForm() {
      try {
        this.disabled = true;

        if (!this.isEmailValid) {
          this.disabled = false;
          this.showError = true;
          return;
        }

        let payload = {
          request_id: this.$route?.query?.request_id,
          email: this.formData?.email,
          first_name: this.formData.firstName,
          last_name: this.formData.lastName,
        };
        this.$store.dispatch("setSelfRegUserDetail", payload);

        const res = await requestUserRegistration(payload);
        if (res?.data?.success) {
          this.$router.push({
            name: "otp-login",
            query: {
              requestId: res.data?.request_id,
              email: this.formData.email,
              from: "Self-Registration",
            },
          });
        } else {
          this.$toast.error(res?.data?.message || "Something went wrong.")
        }
        this.disabled = false;
        this.showError = false;
      } catch (error) {
        console.log("error", error);
        this.disabled = false;
        this.$toast.error("Something went wrong!");
      }
    },
    disableError() {
      this.disabled = false;
    },

    isValidEmail(email) {
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      return emailRegex.test(email);
    },
  },
  computed: {
    isFormFilled() {
      return (
        this.formData?.email &&
        this.formData?.firstName &&
        this.formData?.lastName
      );
    },
    isEmailValid() {
      return this.isValidEmail(this.formData.email);
    },
    //  isFirstNameValid() {
    //   return this.isValidEmail(this.formData.firstName);
    // },
    //  isLastNameValid() {
    //   return this.isValidEmail(this.formData.lastName);
    // },
    loginScreenInfo() {
      return sections.login_screen_info;
    },
    poweredByImgUrl() {
      // return sections.login_screen_info.logo_url
      return `data:image/png;base64,${this.loginScreenInfo.logo}`;
    },
  },
  beforeRouteLeave(to, from, next) {
    if(from?.name === 'SelfRegistrationUserDetail' && to?.name === 'SelfRegistration') {
      this.$store.dispatch("manageSelfRegistrationData", null);
    }
    next();
  },
};
</script>
<style lang="scss">
.custom-container {
  background-color: white;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  height: 100vh;
  width: 100vw;

  .brand {
    height: 100%;
    background-color: var(--brand-color);
    display: grid;
    place-content: center;
    position: relative;

    .welcome {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      z-index: 999;

      #logo {
        width: 200px;
        height: 72px;
        margin-bottom: 5rem;
      }

      p {
        font-size: 32px;
        font-family: "Montserrat";
        color: white;
        margin: 0.5rem 2rem;
        font-weight: 200;
      }
    }

    #bg {
      position: absolute;
      height: 100%;
      width: 100%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #136dd6;
    }
  }

  // .loginImage-cnt {
  //     width: 50%;
  //     height: 100vh;
  //     // overflow: hidden;
  //     display: flex;
  // }
  .form-cnt {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 6%;
    background-color: white;
  }
  .logo {
    width: 200px;
    height: 72px;
  }
  .btnDisable {
    background-color: #ddd;
    color: #858585;
  }
  // .rounded{
  //     border-radius: 10px;
  // }
  .form-cnt-div {
    padding: 3rem;
  }
  input {
    font-size: 16px;
    border-radius: 10px;
    // border: 1px solid  #d8dbe0;
  }
  input:focus {
    outline: 1px solid rgb(59, 130, 246);
  }
  input::placeholder {
    // color: #49454F;
  }
  .login-button {
    border-radius: 10px;
    border-width: 0px;
    font-size: 16px;
    // background-color: #0d69d5;
    font-weight: 700;
  }
  .forget-pass {
    color: rgb(13, 106, 213);
  }
  * {
    font-family: "Poppins", sans-serif !important;
  }
  .auth {
    overflow-y: scroll;
  }
  form {
    max-width: 36rem;
  }
  .hideError {
    visibility: hidden;
  }
  .error {
    position: absolute;
  }
  .loginImg {
    width: 100%;
    height: 100%;
  }
  .tagline {
    font-size: 16px;
    font-weight: 600;
  }
  .poweredBy {
    font-size: 12px;
  }
  .poweredBy_logo {
    max-height: 28px;
    min-height: 20px;
    margin-left: 10px;
  }
  .powered_by_cnt {
    margin-top: 15px;
  }
  .tagline_cnt {
    margin-top: 19px;
  }
  .custom_logo {
    max-height: 130px;
    min-height: 72px;
  }
}
</style>
