var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"custom-container"},[_c('section',{staticClass:"brand"},[_c('div',{attrs:{"id":"bg"}},[_c('img',{staticClass:"loginImg",attrs:{"src":_vm.bgImg}})])]),_c('section',{staticClass:"auth"},[[_c('div',{staticClass:"w-full form-cnt min-h-full"},[_c('form',{staticClass:"pr-10",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"flex flex-col gap-8 pr-0 pl-8 ml-4"},[(_vm.loginInfo)?_c('div',{style:({ paddingBottom: '6px' })},[(_vm.loginScreenInfo.logo_url)?_c('img',{staticClass:"custom_logo",attrs:{"src":_vm.loginScreenInfo.logo_url}}):_vm._e(),_c('div',{staticClass:"tagline_cnt"},[_c('span',{staticClass:"tagline"},[_vm._v(_vm._s(_vm.loginScreenInfo.tagline))])])]):_vm._e(),_c('div',[_c('p',{staticClass:"block mb-2 text-black text-2xl font-semibold"},[_vm._v(" Login to your account ")]),_c('p',{staticClass:"block mb-2 text-black text-sm"},[_vm._v(" Hi "+_vm._s(_vm.activeUserName)+"! Please enter a New Password ")])]),_c('div',[_c('div',{staticClass:"my-8 relative"},[_c('label',{staticClass:"block mb-2 text-sm font-noraml text-gray-900 dark:text-white",attrs:{"for":"Temparary_password"}},[_vm._v("New Password")]),_c('div',{staticClass:"flex items-center relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.password),expression:"formData.password"}],ref:"passRef",staticClass:"bg-gray-50 border border-gray-300 text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12",class:(_vm.passwordCheck && !_vm.isPassValidReqX) ||
                        (!_vm.isPassValidReqX && _vm.isPassFilled)
                          ? 'border-red-600'
                          : 'border-gray-300',attrs:{"type":"password","id":"password","autocomplete":"new-password","placeholder":"Enter Password"},domProps:{"value":(_vm.formData.password)},on:{"blur":_vm.handleInputBlur,"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "password", $event.target.value)},_vm.inputCheck]}}),(!_vm.isPassVisible)?_c('EyeClosed',{staticClass:"absolute right-4 cursor-pointer",on:{"click":function($event){return _vm.togglePass('passRef')}}}):_c('Eye',{staticClass:"absolute right-4 cursor-pointer",on:{"click":function($event){return _vm.togglePass('passRef')}}})],1),(_vm.passwordCheck && !_vm.isPassValidReqX)?_c('span',{staticClass:"text-red-600 text-xs"},[_vm._v("Password must contain atleast 12 characters, a lower case letter, an uppercase letter, a number and a special character.")]):_vm._e()]),_c('div',{staticClass:"my-8 relative"},[_c('label',{staticClass:"block mb-2 text-sm font-noraml text-gray-900 dark:text-white",attrs:{"for":"Temparary_password"}},[_vm._v("Confirm Password")]),_c('div',{staticClass:"flex items-center relative"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.confPassword),expression:"formData.confPassword"}],ref:"confPassRef",staticClass:"bg-gray-50 border text-gray-900 text-base rounded focus:ring-blue-500 focus:border-blue-500 block w-full px-4 py-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 h-12",class:_vm.passConfCheck && !_vm.isConfPassValid
                          ? 'border-red-600'
                          : 'border-gray-300',attrs:{"type":"password","id":"confPassword","autocomplete":"new-password","placeholder":"Re Enter Password"},domProps:{"value":(_vm.formData.confPassword)},on:{"blur":_vm.handleInputBlur,"change":_vm.handleChange,"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formData, "confPassword", $event.target.value)},_vm.confPassInputCheck]}}),(!_vm.isconfPassVisible)?_c('EyeClosed',{staticClass:"absolute right-4 cursor-pointer",on:{"click":function($event){return _vm.togglePass('confPassRef')}}}):_c('Eye',{staticClass:"absolute right-4 cursor-pointer",on:{"click":function($event){return _vm.togglePass('confPassRef')}}})],1),_c('span',{staticClass:"text-red-600 text-xs error",class:{
                      hideError:
                        !_vm.passConfCheck ||
                        (_vm.isConfPassValid && _vm.isConfPassFilled),
                    }},[_vm._v("Password doesn't match")])])]),_c('div',[_c('button',{staticClass:"bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full h-12 cursor-pointer",class:{ btnDisable: !_vm.isFormFilled || _vm.disabled },attrs:{"type":"submit","disabled":!_vm.isFormFilled || _vm.disabled}},[(_vm.disabled)?_c('Loader',{staticClass:"h-8 w-8",attrs:{"size":8}}):_vm._e(),_vm._v(" "+_vm._s(!_vm.disabled ? "Continue" : "")+" ")],1),(_vm.loginScreenInfo.powered_by && _vm.loginInfo)?_c('div',{staticClass:"flex justify-center items-center powered_by_cnt"},[_c('span',{staticClass:"poweredBy"},[_vm._v("Powered by")]),_c('img',{staticClass:"poweredBy_logo",attrs:{"src":_vm.NeotasLogo}})]):_vm._e()])])])])]],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }